/**
 * Helper function to validate if a value exists in an enum object
 * @param {string} value - The value to validate
 * @param {Object} enumObject - The enum object containing valid values
 * @param {string} fieldName - The name of the field being validated
 * @throws {Error} If the value is invalid
 */
export const validateEnumValue = (value, enumObject, fieldName) => {``
    const validValues = Object.values(enumObject);
    if (!validValues.includes(value)) {
      throw new Error(`Invalid ${fieldName}: ${value}. Must be one of: ${validValues.join(', ')}`);
    }
};

/**
 * Helper function to validate required fields
 * @param {string} value - The value to validate
 * @param {string} fieldName - The name of the field being validated
 * @throws {Error} If the value is missing
 */
export const validateRequiredField = (value, fieldName) => {
    if (!value) {
      throw new Error(`${fieldName} is required`);
    }
  }

/**
 * Helper function to validate Unix timestamp
 * @param {number} timestamp - The timestamp to validate
 * @throws {Error} If the timestamp is invalid
 */
export const validateTimestamp = (timestamp) => {
    if (timestamp) {
        // Check if it's a number and a valid Unix timestamp (between 1970 and 2100)
        if (!Number.isInteger(timestamp) ||
            timestamp < 0 ||
            timestamp > 4102444800000) { // Year 2100
        throw new Error('Invalid timestamp: must be a valid Unix timestamp in milliseconds');
        }
    }
}

/**
 * Helper function to validate ECC Account IDs
 * @param {Object.<string, string>} eccAccountIDs - Dictionary of ECC account IDs
 * @throws {Error} If the eccAccountIDs format is invalid
 */
export const validateEccAccountIDs = (eccAccountIDs) => {
    // Check if it's an object first
    if (!eccAccountIDs || typeof eccAccountIDs !== 'object' || Array.isArray(eccAccountIDs)) {
        throw new Error('eccAccountIDs must be an object');
    }

    // If not empty, must contain 'source' key
    if (Object.keys(eccAccountIDs).length > 0 && !('source' in eccAccountIDs)) {
        throw new Error('eccAccountIDs must contain a "source" key when not empty');
    }

    // Validate all values are strings
    for (const [key, value] of Object.entries(eccAccountIDs)) {
        if (typeof value !== 'string') {
        throw new Error(`eccAccountIDs values must be strings, found invalid value for key "${key}"`);
        }
    }
}

/**
 * Helper function to validate object
 * @param {Object} obj - Dictionary object
 * @param {string} objName - Name of the object for error messages
 * @throws {Error} If the input is not an object
 */
export const validateObject = (obj, objName) => {
    // Check if it's an object first
    if (!obj || typeof obj !== 'object' || Array.isArray(obj)) {
        throw new Error(`${objName} must be an object`);
    }
}