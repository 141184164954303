import { createSlice } from '@reduxjs/toolkit';
import {
  APP_LAUNCHER_AGENT_511,
  APP_LAUNCHER_AVIVE,
} from '@rsos/constants/appLauncherTypes';

export const AGENT_511_MAP_CARD = 'agent511MapCard';
export const AVIVE_APP_LAUNCHER_MAP_CARD = 'aviveAppLauncherMapCard';
export const AXON_MAP_CARD = 'axonMapCard';
export const EAGLE_EYE_MAP_CARD = 'eagleEyeMapCard';
export const DYNAMIC_LAYERS_CARD = 'dynamicLayersCard';
export const RADE_MAP_CARD = 'radeMapCard';
export const STATIC_LAYERS_CARD = 'staticLayersCard';
export const MEDIA_CARD = 'mediaCard';
export const LIVE_VIDEO_CARD = 'liveVideoCard';
export const OUTBOUND_SMS_CARD = 'outboundSMSCard';
export const AIR_AMBULANCE_CARD = 'airAmbulanceCard';

const initialState = {
  cards: {
    [AGENT_511_MAP_CARD]: false,
    [AVIVE_APP_LAUNCHER_MAP_CARD]: false,
    [AXON_MAP_CARD]: false,
    [EAGLE_EYE_MAP_CARD]: false,
    [DYNAMIC_LAYERS_CARD]: false,
    [RADE_MAP_CARD]: false,
    [STATIC_LAYERS_CARD]: false,
    [MEDIA_CARD]: false,
    [LIVE_VIDEO_CARD]: false,
    [OUTBOUND_SMS_CARD]: false,
    [AIR_AMBULANCE_CARD]: false,
  },
  mapCardData: null, // used for mapping spatial object data to corresponding map marker
};

const mapCardSlice = createSlice({
  name: 'mapCard',
  initialState,
  reducers: {
    closeAllCards: () => {
      return {
        ...initialState,
      };
    },
    closeCard: (state, action) => {
      return {
        ...state,
        cards: {
          ...state.cards,
          [action.payload]: false,
        },
      };
    },
    setCardsToRender: (state, action) => {
      // Set only 1 card to be visible at a time per requirements
      return {
        ...state,
        cards: {
          ...initialState.cards,
          [action.payload]: true,
        },
      };
    },
    /**
     * @param {Object} action.payload
     * @param {Object} action.payload.layerData - spatial layer data
     * @param {Object} action.payload.spatialObject - spatial object data
     * @param {Object} action.payload.onCardClose - callback to handle
     * map/marker functionality when closing the card
     */
    setMapCardData: (state, action) => {
      return {
        ...state,
        mapCardData: action.payload,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase('radeSlice/show', state => {
        return {
          ...state,
          cards: {
            ...initialState.cards,
            [RADE_MAP_CARD]: true,
          },
          mapCardData: null,
        };
      })
      .addCase('appLauncher/selectAppLauncherApp', (state, action) => {
        if (action.payload?.appName === APP_LAUNCHER_AVIVE) {
          return {
            ...state,
            cards: {
              ...initialState.cards,
              [AVIVE_APP_LAUNCHER_MAP_CARD]: true,
            },
            mapCardData: null,
          };
        } else if (action.payload?.appName === APP_LAUNCHER_AGENT_511) {
          return {
            ...state,
            cards: {
              ...initialState.cards,
              [AGENT_511_MAP_CARD]: true,
            },
            mapCardData: null,
          };
        }
      })
      .addCase('APP_INIT_START', state => {
        return state;
      });
  },
});

export const {
  closeAllCards,
  closeCard,
  setCardsToRender,
  setMapCardData,
} = mapCardSlice.actions;
export default mapCardSlice.reducer;
