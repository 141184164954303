export const BURGLARY = 'BURGLARY';
export const SILENT_ALARM = 'SILENT_ALARM';
export const HOLDUP = 'HOLDUP';
export const OTHER = 'OTHER';
export const MOBILE_PANIC = 'MOBILE_PANIC';
export const CRASH = 'CRASH';
export const TRAIN_DERAILMENT = 'TRAIN_DERAILMENT';
export const ACTIVE_ASSAILANT = 'ACTIVE_ASSAILANT';
export const MEDICAL = 'MEDICAL';
export const CO = 'CO';
export const FIRE = 'FIRE';

export const CALL_TO_EMERGENCY_NUMBER = 'CALL_TO_EMERGENCY_NUMBER';
export const CORPORATE_CALL_TO_EMERGENCY_NUMBER =
  'CORPORATE_CALL_TO_EMERGENCY_NUMBER';
export const EXECUTIVE_CALL_TO_EMERGENCY_NUMBER =
  'EXECUTIVE_CALL_TO_EMERGENCY_NUMBER';

export const TEST_BURGLARY = 'TEST_BURGLARY';
export const TEST_SILENT_ALARM = 'TEST_SILENT_ALARM';
export const TEST_HOLDUP = 'TEST_HOLDUP';
export const TEST_OTHER = 'TEST_OTHER';
export const TEST_MOBILE_PANIC = 'TEST_MOBILE_PANIC';
export const TEST_CRASH = 'TEST_CRASH';
export const TEST_TRAIN_DERAILMENT = 'TEST_TRAIN_DERAILMENT';
export const TEST_ACTIVE_ASSAILANT = 'TEST_ACTIVE_ASSAILANT';
export const TEST_MEDICAL = 'TEST_MEDICAL';
export const TEST_CO = 'TEST_CO';
export const TEST_FIRE = 'TEST_FIRE';

export const TEST_CALL_TO_EMERGENCY_NUMBER = 'TEST_CALL_TO_EMERGENCY_NUMBER';
export const TEST_CORPORATE_CALL_TO_EMERGENCY_NUMBER =
  'TEST_CORPORATE_CALL_TO_EMERGENCY_NUMBER';
export const TEST_EXECUTIVE_CALL_TO_EMERGENCY_NUMBER =
  'TEST_EXECUTIVE_CALL_TO_EMERGENCY_NUMBER';
