import { getMediaHost } from '@rsos/utils/metaTags';
import { baseQueryArgs, baseExtraOptions, rsosApi } from '../rsosApi';

const mediaBaseUrl = getMediaHost();
export const endpoint = `${mediaBaseUrl}/v1/media-request`;

export const MEDIA_UPLOAD = 'media_upload';
export const VIDEO_STREAMING = 'video_streaming';

export const mediaApi = rsosApi.injectEndpoints({
  endpoints: builder => ({
    postMediaRequest: builder.mutation({
      query: ({
        requestType,
        accountId,
        authorityName,
        callerId,
        callStartTime,
      }) => ({
        ...baseQueryArgs,
        url: endpoint,
        method: 'POST',
        body: {
          request_type: requestType,
          account_id: accountId,
          authority_name: authorityName,
          caller_id: callerId,
          call_start_time: callStartTime,
        },
      }),
      extraOptions: {
        ...baseExtraOptions,
      },
    }),
    getMediaSource: builder.query({
      query: key => ({
        ...baseQueryArgs,
        url: `${endpoint}/retrieve/${key}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1800,
      extraOptions: {
        ...baseExtraOptions,
        maxRetries: 1, // prevents the auto retry on error in favor of the polling.
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  usePostMediaRequestMutation,
  useGetMediaSourceQuery,
  useLazyGetMediaSourceQuery,
} = mediaApi;
