import { MW_5_13_0 } from '@rsos/reduxDataStore/src/constants/migrationVersions';

/**
 * Migration function that takes a version number and returns the new state for the release
 * @param {Object} state - state
 * @param {String} reducer - which reducer needs a migration
 * @param {Number} version - version number that maps to the migration version number
 */
export const runMigration = (state, reducer, version) => {
  switch (reducer) {
    case 'airAmbulance': {
      if (version === MW_5_13_0) {
        return getStateForMWFiveThirteen(state, reducer);
      }

      return state;
    }
  }
};

const getStateForMWFiveThirteen = (state, reducerName) => {
  if (reducerName === 'airAmbulance') {
    return {
      ...state,
      requests: { calls: {}, nonCalls: {} },
      removedAirAmbulances: { calls: {}, nonCalls: {} },
      selectedAirAmbulance: null,
      formPageToShow: null,
      hoveredAirAmbulance: null,
    };
  }
};
