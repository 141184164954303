/**
 * Function that returns the most recent call object
 * @param {Object} call callQueue[callerID]
 */
export const getMostRecentLoc = call => {
  // TODO: passing in an object {} will always pass the condition and cause unexpected errors. add tests
  if (call) {
    const { locations, secondaryLocations } = call.core;
    const mostRecentLocIdx = locations?.length - 1;
    const mostRecentSecondaryLocIdx = secondaryLocations?.length - 1;
    if (
      locations &&
      locations.length &&
      secondaryLocations &&
      secondaryLocations.length
    ) {
      return locations[mostRecentLocIdx].call_start_time >
        secondaryLocations[mostRecentSecondaryLocIdx].call_start_time
        ? locations[mostRecentLocIdx]
        : secondaryLocations[mostRecentSecondaryLocIdx];
    }

    return locations?.[mostRecentLocIdx]
      ? locations?.[mostRecentLocIdx]
      : secondaryLocations?.[mostRecentSecondaryLocIdx]
      ? secondaryLocations?.[mostRecentSecondaryLocIdx]
      : null;
  }
  return null;
};

export const getMostRecentLocations = call => {
  const locs = {
    mostRecentLocation: null,
    mostRecentLocationIndex: null,
    mostRecentSecondaryLocation: null,
    mostRecentSecondaryLocationIndex: null,
  };
  // TODO: passing in an object {} will always pass the condition and cause unexpected errors. add tests
  if (call) {
    const { locations, secondaryLocations } = call.core || {};
    const mostRecentLocIdx = locations?.length - 1;
    const mostRecentSecondaryLocIdx = secondaryLocations?.length - 1;

    if (locations && locations.length) {
      locs.mostRecentLocation = locations[mostRecentLocIdx];
      locs.mostRecentLocationIndex = mostRecentLocIdx;
    }
    if (secondaryLocations && secondaryLocations.length) {
      locs.mostRecentSecondaryLocation =
        secondaryLocations[mostRecentSecondaryLocIdx];
      locs.mostRecentSecondaryLocationIndex = mostRecentSecondaryLocIdx;
    }
  }
  return locs;
};
