import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PORTAL_POPOVER_PRIORITY } from '@rsos/capstone/src/constants/portalPriorities';
import Portal from '../../Portal';

const Relative = styled.div`
  position: relative;
  display: ${({ cssDisplayProperty }) =>
    cssDisplayProperty ? cssDisplayProperty : 'inline-flex'};
`;

const PopoverContent = styled.div`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  z-index: ${({ top, left }) => (top && left ? 3 : -1)};
`;

const TriggerWrapper = styled.div`
  display: ${({ cssDisplayProperty }) =>
    cssDisplayProperty ? cssDisplayProperty : 'inline-flex'};
`;

/**
 * Customizable Popover component
 *
 * @param {node} trigger - element that triggers the popover
 * @param {node} children - popover element
 * @param {boolean} isOpen - toggles the visibility of the popover
 * @param {number} xOffset - x offset from the element
 * @param {number} yOffset - y offset from the element
 * @param {string} popoverID - popover ID attribute
 * @param {boolean} useCustomOffset - should the popover display in a specific offset from the
 *   trigger element
 * @param {Boolean} useCenterXOffset - Display the popover content in the center x offset from the
 *   trigger element. If this prop is used, then the `useCustomOffset` and `xOffset` props will be
 *   ignored.
 * @param {Boolean} useCenterYOffset - Dispaly the popover content in the center y offset from the
 *   trigger element. If this prop is used, then the `useCustomOffset` and `yOffset` props will be
 *   ignored.
 * @param {String} cssDisplayProperty - CSS display property to override the component's default
 *   inline-flex value.
 */
const Popover = ({
  trigger: Trigger,
  children,
  isOpen,
  xOffset = 0,
  yOffset = 0,
  popoverID,
  useCustomOffset = false,
  useCenterXOffset = false,
  useCenterYOffset = false,
  cssDisplayProperty,
}) => {
  const triggerRef = useRef();
  const contentRef = useRef();

  const [boundingClient, setBoundingClient] = useState();
  const [contentBoundingClient, setContentBoundingClient] = useState();

  useEffect(() => {
    const rect = triggerRef.current.getBoundingClientRect();
    setBoundingClient(rect);

    const contentRect = contentRef?.current?.getBoundingClientRect();
    setContentBoundingClient(contentRect);
  }, [isOpen]);

  return (
    <Relative cssDisplayProperty={cssDisplayProperty}>
      <TriggerWrapper cssDisplayProperty={cssDisplayProperty} ref={triggerRef}>
        {Trigger}
      </TriggerWrapper>
      <Portal id={popoverID} priority={PORTAL_POPOVER_PRIORITY}>
        {isOpen && (
          <PopoverContent
            ref={contentRef}
            top={
              useCenterYOffset
                ? boundingClient.top + boundingClient.height / 2
                : useCustomOffset
                ? boundingClient.top + yOffset
                : boundingClient.top - yOffset + boundingClient.height / 2
            }
            left={
              useCenterXOffset
                ? boundingClient.left +
                  (boundingClient.width - contentBoundingClient?.width) / 2
                : useCustomOffset
                ? boundingClient.left + xOffset
                : boundingClient.left + xOffset + boundingClient.width
            }
          >
            {children}
          </PopoverContent>
        )}
      </Portal>
    </Relative>
  );
};

Popover.propTypes = {
  trigger: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  xOffset: PropTypes.number,
  yOffset: PropTypes.number,
  popoverID: PropTypes.string.isRequired,
  useCustomOffset: PropTypes.bool,
  useCenterXOffset: PropTypes.bool,
  useCenterYOffset: PropTypes.bool,
  cssDisplayProperty: PropTypes.string,
};

export default Popover;
