/**
 * Wrapper for the new Gainsight PX implementation.
 * This file provides backward compatibility for existing applications
 * while using the new shared library internally.
 */
import { gspx } from '@rsos/gainsight';
import { captureExceptionWithScope } from '@rsos/utils/sentry';

class CapstoneGainSightPX {
  constructor() {
    this.store = null;
    this.trackingID = gspx.trackingID;
  }

  /**
   * Initialize with Redux store
   */
  initialize(store) {
    this.store = store;
    // Initialize the new implementation with a data provider that gets data from Redux
    gspx.initialize({
      // Data provider implementation
      getUserData: () => {
        const user = this.store?.getState()?.sinatra?.user;
        if (!user) return null;

        return {
          profileId: user.profile.id,
          application: user.currentRole.application,
          email: user.profile.email,
          firstName: user.profile.first_name,
          lastName: user.profile.last_name,
          dateJoined: user.profile.date_joined,
          lastActiveSession: user.profile.last_active_session,
          role: user.currentRole
        };
      },
      getAccountData: () => {
        const psap = this.store?.getState()?.psaps?.currentPSAP;
        if (!psap) return null;

        return {
          accountId: psap.account_id,
          displayName: psap.display_name,
          contactEmail: psap.contact_email,
          capabilities: psap.active_capabilities,
          population: psap.population,
          state: psap.state,
          dispatchType: psap.dispatch_type
        };
      },
      getPSAPData: () => {
        const psap = this.store?.getState()?.psaps?.currentPSAP;
        if (!psap) return null;

        return {
          accountId: psap.account_id
        };
      },
      getSelectedCallData: () => {
        const state = this.store?.getState();
        if (!state?.emergencyCalls) return null;

        const selectedCallerID = state.emergencyCalls.selectedCallerID;
        if (!selectedCallerID || !state.emergencyCalls.callQueue) return null;

        const call = state.emergencyCalls.callQueue[selectedCallerID];
        if (!call?.core) return null;

        const { locations, secondaryLocations } = call.core;
        const firstLoc = locations?.[0] || secondaryLocations?.[0];

        return {
          payloadId: firstLoc?.extra_info?.payload_id,
          callStartTime: firstLoc?.call_start_time
        };
      }
    }, { captureError: captureExceptionWithScope });
  }

  /**
   * Identify current user in Gainsight PX
   */
  identify() {
    gspx.identify();
  }

  /**
   * Add user and account identifiers
   */
  addIdentifier(userInfo = {}, accountInfo = {}) {
    gspx.addIdentifier(userInfo, accountInfo);
  }

  /**
   * Normalize user info from Redux state.
   */
  normalizeGSPXUserInfo(userState) {
    // If userState provided, temporarily override store state
    if (userState) {
      const originalStore = this.store;
      this.store = {
        getState: () => ({ sinatra: { user: userState } })
      };
      const result = gspx.normalizeUserData(gspx.dataProvider.getUserData());
      this.store = originalStore;
      return result;
    }

    // Otherwise use the current store state
    return gspx.normalizeUserData(gspx.dataProvider.getUserData());
  }

  /**
   * @deprecated Use trackEvent instead
   * @param {string} category Event category
   * @param {Object} eventInfo Event data
   */
  trackCustomEvent(category, eventInfo = {}) {
    gspx.trackCustomEvent(category, eventInfo);
  }

  /**
   * Track an event
   * @param {Object} params Event parameters
   */
  trackEvent(params) {
    gspx.trackEvent(params);
  }
}

// Export singleton instance
export default new CapstoneGainSightPX();
