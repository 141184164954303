import styled from 'styled-components';
import Box from '../../Box';

export const ModalDialog = styled(Box).attrs(p => ({
  width: p.width || 'auto',
  minHeight: p.minHeight || '200px',
  maxHeight: p.maxHeight || '80%',
  borderRadius: '4px',
}))``;

export const ModalDialogInner = styled(Box).attrs(p => ({
  p: p.padding ? p.padding : 45,
  bg: p.transparent ? 'transparent' : 'background',
  position: 'relative',
  mb: '10px',
  noShrink: true,
  height: '100%',
}))`
  box-shadow: ${p =>
    p.transparent ? 'transparent' : ' 2px 2px 18px 1px rgba(0, 0, 0, 0.13)'};
  border-radius: 4px;
`;
