// The version constant names should match the targeted release names such that
// we can keep track of what versions have been released

export const MW_4_24 = 10;

export const MW_4_24_1 = 11;

export const MW_4_24_2 = 12;

export const MW_5_0_0 = 13;

export const MW_5_10_0 = 14;

export const MW_5_11_0 = 15;

export const MW_5_12_0 = 16;

export const MW_5_13_0 = 17;

// Redux persist uses -1 to indicate their default version. Use negative version
// to indicate we currently don't have this scheduled for a release
