import {
  GSPX_TRACKING_IDS,
  PRODUCTION_HOSTNAMES
} from './constants/internalConstants';

/**
 * Determines the GainSightPX tracking ID based on the current hostname.
 * Returns prod ID for production hostnames and hostnames containing 'prod',
 * staging ID for hostnames containing 'staging' or 'sandbox',
 * qa ID for hostnames containing 'qa',
 * and falls back to dev ID for all other cases.
 * @returns {string} The environment-specific GainSightPX tracking ID
 */
export const determineGSPXTrackingID = () => {
  const { hostname } = window.location;

  if (PRODUCTION_HOSTNAMES.includes(hostname) || hostname.includes('prod')) return GSPX_TRACKING_IDS.prod;
  if (hostname.includes('staging') || hostname.includes('sandbox')) return GSPX_TRACKING_IDS.staging;
  if (hostname.includes('qa')) return GSPX_TRACKING_IDS.qa;

  return GSPX_TRACKING_IDS.dev;
};

/**
 * Safely parses a date string to a Unix timestamp.
 *
 * @param {string} dateString - The date string to parse (e.g., ISO 8601 format)
 * @returns {number|null} The Unix timestamp in milliseconds, or null if parsing fails
 */
export const safeParseDate = (dateString) => {
  const parsed = Date.parse(dateString);
  return isNaN(parsed) ? null : parsed;
};

/**
 * Gets the environment suffix based on the tracking ID.
 * Used for differentiating user IDs across different environments.
 *
 * @param {string} trackingID - The tracking ID from GSPX_TRACKING_IDS
 * @returns {string} The environment suffix ('staging', 'qa', 'dev', or 'prod')
 */
export const getEnvironmentSuffix = (trackingID) => {
  switch (trackingID) {
    case GSPX_TRACKING_IDS.staging:
      return 'staging';
    case GSPX_TRACKING_IDS.qa:
      return 'qa';
    case GSPX_TRACKING_IDS.dev:
      return 'dev';
    default:
      return 'prod';
  }
};

  /**
   * Helper function to ensure undefined values are preserved in nested objects
   * @param {Object} obj - Dictionary object
   * @returns {Object} obj with undefined values preserved
   */
  export const preserveUndefined = (obj) => {
    if (obj && typeof obj === 'object' && !Array.isArray(obj)) {
      return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [
          key,
          value === undefined ? null : preserveUndefined(value)
        ])
      );
    }
    return obj;
  }

/**
 * Default error capture function that logs to console
 * @param {Error} error - The error object
 * @param {Object} scope - Additional context about the error
 */
export const defaultCaptureError = (error, scope = {}) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error('[GainSight Error]', error.message, {
      error,
      scope,
      stack: error.stack
    });
  }
};