export const GPX_EVENT_TYPES = {
  CLICK: 'click',
  API_RESPONSE: 'api_response',
  API_REQUEST: 'api_request',
  API_PUSH: 'api_push',
  INFO: 'info',
};

export const GPX_CAPABILITIES = {
  VIDEO_STREAMING: 'video_streaming',
  MEDIA_UPLOAD: 'media_upload',
  TF911: 'text_from_911',
};

export const GPX_FEATURES = {
  CIRCUS_LIVE_VIDEO: 'circus_live_video',
  CIRCUS_MEDIA_UPLOAD: 'circus_media_upload',
  CIRCUS_DISPLAY_MEDIA: 'circus_display_media',
  TF911: 'text_from_911',
  TF911_CONVEY: 'text_from_911_convey',
  TF911_OUTBOUND: 'text_from_911_outbound',
  VT911: 'video_to_911',
};

export const GPX_PARTNERS = {
  CIRCUS: 'circus',
};

export const GPX_ROUTES = {
  MEDIA: 'Media',
  COMMUNICATOR: 'Communicator',
};

/**
 * These are root level event name identifiers (e.g., UNITE_USAGE).
 * Preferrably we should use specific name to distiguish
 * different products/apps.
 */
export const GPX_EVENT_NAME = {
  UNITE_USAGE: 'UNITE usage',
};

// Freeze the enum to prevent modifications
Object.freeze(GPX_EVENT_TYPES);
Object.freeze(GPX_CAPABILITIES);
Object.freeze(GPX_FEATURES);
Object.freeze(GPX_PARTNERS);
Object.freeze(GPX_ROUTES);
Object.freeze(GPX_EVENT_NAME);
