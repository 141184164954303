/**
 * @typedef {import('./types').UserData} UserData
 * @typedef {import('./types').AccountData} AccountData
 * @typedef {import('./types').DataProvider} DataProvider
 */


import { getSessionAge } from '@rsos/base-utils/metaTags';
import {
  normalizeUserInfo,
  normalizeAccountInfo
} from './normalizers';
import {
  determineGSPXTrackingID,
  defaultCaptureError,
  preserveUndefined,
} from './utils';
import {
  validateEnumValue,
  validateRequiredField,
  validateTimestamp,
  validateEccAccountIDs,
  validateObject
} from './validators';
import {
  GPX_EVENT_TYPES,
  GPX_FEATURES,
  GPX_CAPABILITIES,
  GPX_PARTNERS,
  GPX_ROUTES,
  GPX_EVENT_NAME
} from './constants/trackingEnums';
import { GSPX_LAST_IDENTIFIED } from './constants/internalConstants';

/**
 * Main class for Gainsight PX integration
 */
class CapstoneGainSightPX {
  /** @type {DataProvider} */
  dataProvider;

  /** @type {string} */
  trackingID;

  /** @type {function(Error, Object): void} */
  captureError;

  constructor() {
    this.dataProvider = null;
    this.trackingID = determineGSPXTrackingID();
    this.captureError = defaultCaptureError;
  }

  /**
   * Identifies a user and account in the Gainsight PX platform and updates the last identified timestamp.
   * Note: This method expects normalized data from normalizeUserInfo and normalizeAccountInfo.
   * Do not pass raw user/account data directly.
   *
   * @param {Object} [userInfo={}] - Normalized user info from normalizeUserInfo
   * @param {Object} [accountInfo={}] - Normalized account info from normalizeAccountInfo
   * @see normalizeUserInfo
   * @see normalizeAccountInfo
   */
  addIdentifier(userInfo = {}, accountInfo = {}) {
    if (window.aptrinsic) {
      window.aptrinsic('identify', userInfo, accountInfo);
      localStorage.setItem(GSPX_LAST_IDENTIFIED, Math.floor(Date.now() / 1000));
    }
  }

  /**
   * Identifies the current user in Gainsight PX if they haven't been identified in the current session
   * @returns {void}
   */
  identify() {
    try {
      // Check if user was already identified in current session
      const sessionStart = Date.now() / 1000 - getSessionAge();
      const lastIdentified = localStorage.getItem(GSPX_LAST_IDENTIFIED);

      if (lastIdentified && lastIdentified > sessionStart) {
        return; // User already identified in current session
      }

      if (!window.aptrinsic) {
        throw new Error('Tracking service not initialized');
      }

      if (!this.dataProvider) {
        throw new Error('Data provider not initialized');
      }

      const userData = this.dataProvider.getUserData();
      const accountData = this.dataProvider.getAccountData();

      if (!userData || !accountData) {
        throw new Error('Required user or account data not available');
      }

      // Get normalized data using class methods that handle trackingID
      const userInfo = this.normalizeUserData(userData);
      const accountInfo = this.normalizeAccountData(accountData);

      this.addIdentifier(userInfo, accountInfo);
    } catch (error) {
      this.captureError(
        new Error('Failed to identify user'),
        {
          error,
          context: {
            hasDataProvider: !!this.dataProvider,
            hasUserData: !!this.dataProvider?.getUserData(),
            hasAccountData: !!this.dataProvider?.getAccountData()
          }
        }
      );
    }
  }

  /**
   * Normalizes user data by adding environment-specific tracking ID
   * @param {UserData} userData - Raw user data from data provider
   * @returns {Object} Normalized user info for Gainsight PX
   */
  normalizeUserData(userData) {
    try {
      const normalizedUserInfo = normalizeUserInfo(userData, this.trackingID);
      return normalizedUserInfo;
    } catch (error) {
      this.captureError(
        new Error('Failed to normalize user data'),
        {
          error,
          context: {
            userData
          }
        }
      );
      return null;
    }
  }

  /**
   * Normalizes account data
   * @param {AccountData} accountData - Raw account data from data provider
   * @returns {Object} Normalized account info for Gainsight PX
   */
  normalizeAccountData(accountData) {
    try {
      const normalizedAccountInfo = normalizeAccountInfo(accountData);
      return normalizedAccountInfo;
    } catch (error) {
      this.captureError(
        new Error('Failed to normalize account data'),
        {
          error,
          context: {
            accountData
          }
        }
      );
      return null;
    }
  }

  /**
   * Initializes the Gainsight PX tracking instance with a data provider and optional configuration.
   * @param {DataProvider} dataProvider - Data provider implementation
   * @param {Object} [config] - Optional configuration object
   * @param {function(Error, Object): void} [config.captureError] - Custom error logging function
   */
  initialize(dataProvider, config = {}) {
    if (!dataProvider ||
      typeof dataProvider.getUserData !== 'function' ||
      typeof dataProvider.getAccountData !== 'function' ||
      typeof dataProvider.getPSAPData !== 'function' ||
      typeof dataProvider.getSelectedCallData !== 'function'
    ) {
      this.captureError(new Error('Invalid data provider: must implement getUserData, getAccountData, getPSAPData, and getSelectedCallData methods'));
    }

    this.dataProvider = dataProvider;

    if (config.captureError) {
      if (typeof config.captureError !== 'function') {
        this.captureError(new Error('Invalid error capture function provided: must be a function'));
      } else {
        this.captureError = config.captureError;
      }
    }
  }

  /**
   * Tracks a custom event in GainSight PX analytics platform.
   *
   * Required Parameters:
   * @param {Object} params - Event parameters
   * @param {string} params.name - The name of the event
   * @param {GPX_EVENT_TYPES[keyof GPX_EVENT_TYPES]} params.type - The type of the event. Must be one of GPX_EVENT_TYPES values
   * @param {GPX_FEATURES[keyof GPX_FEATURES]} params.feature - The feature associated with the event. Must be one of GPX_FEATURES values
   * @param {GPX_CAPABILITIES[keyof GPX_CAPABILITIES]} params.capability - The capability being used. Must be one of GPX_CAPABILITIES values
   * @param {GPX_ROUTES[keyof GPX_ROUTES]} params.route - The current route path. Must be one of GPX_ROUTES values
   *
   * Optional Parameters:
   * @param {GPX_PARTNERS[keyof GPX_PARTNERS]} [params.partner] - Partner identifier if applicable. Must be one of GPX_PARTNERS values
   * @param {Object} [params.entities] - Related entity information
   * @param {Object} [params.additionalKeys] - Additional custom key-value pairs
   * @param {number} [params.timestamp] - Unix timestamp of the event. Defaults to current time
   * @param {string} [params.userID] - User identifier. Falls back to current user's ID
   * @param {Object.<string, string>} [params.eccAccountIDs] - ECC account identifiers
   * @param {string} [params.gainsightEventName=GPX_EVENT_NAME.UNITE_USAGE] - GainSight event name. Must be one of GPX_EVENT_NAME values
   *
   * @throws {Error} When required parameters are missing
   * @throws {Error} When tracking service is not initialized
   * @throws {Error} When type, feature, capability, partner or route are not valid enum values
   * @throws {Error} When timestamp is invalid
   * @throws {Error} When eccAccountIDs is invalid
   * @throws {Error} When additionalKeys is not an object
   * @throws {Error} When entities is not an object
   *
   * @example
   * trackEvent({
   *   name: 'request_video_stream',
   *   type: GPX_EVENT_TYPES.CLICK,
   *   feature: GPX_FEATURES.CIRCUS_LIVE_VIDEO,
   *   capability: GPX_CAPABILITIES.VIDEO_STREAMING,
   *   route: GPX_ROUTES.MEDIA,
   *   partner: GPX_PARTNERS.CIRCUS,
   *   additionalKeys: { 'live_video_url': videoURL }
   * });
   */
  trackEvent({
    name,
    type,
    feature,
    capability,
    route,
    partner,
    entities,
    additionalKeys,
    timestamp,
    userID,
    eccAccountIDs,
    gainsightEventName = GPX_EVENT_NAME.UNITE_USAGE
  }) {
    try {
      // Check if tracking and data provider are available
      if (!window.aptrinsic) {
        throw new Error('Tracking service not initialized');
      }

      if (!this.dataProvider) {
        throw new Error('Data provider not initialized. Call initialize() first');
      }

      // Validate required parameters are present
      validateRequiredField(name, 'Event name');
      validateRequiredField(type, 'Event type');
      validateRequiredField(feature, 'Feature');
      validateRequiredField(capability, 'Capability');
      validateRequiredField(route, 'Route');

      // Validate enum values
      validateEnumValue(type, GPX_EVENT_TYPES, 'Event type');
      validateEnumValue(feature, GPX_FEATURES, 'Feature');
      validateEnumValue(capability, GPX_CAPABILITIES, 'Capability');
      validateEnumValue(route, GPX_ROUTES, 'Route');
      validateEnumValue(gainsightEventName, GPX_EVENT_NAME, 'Gainsight event name');

      if (partner) {
        validateEnumValue(partner, GPX_PARTNERS, 'Partner');
      }

      // Validate timestamp
      validateTimestamp(timestamp);

      // Get data from provider
      const userData = this.dataProvider.getUserData() ?? {};
      const psapData = this.dataProvider.getPSAPData() ?? {};
      const callData = this.dataProvider.getSelectedCallData() ?? {};

      const storedUserID = userData.profileId;
      const psapID = psapData.accountId;
      const payloadID = callData.payloadId;
      const callStartTime = callData.callStartTime;

      // Validate passed entities if present and not null
      if (entities != null) {
        validateObject(entities, 'entities');
      }

      // Prepare user ID, session ID, ECC account IDs, additional keys and entities
      const finalUserID = userID ?? storedUserID;
      const finalEccAccountIDs = eccAccountIDs ?? (psapID ? { source: psapID } : {});
      const finalAdditionalKeys = additionalKeys ?? {};
      const finalEntities = {
        ...(entities ?? {}),
        ...(payloadID ? { payload_id: payloadID } : {}),
        ...(callStartTime ? { call_start_time: callStartTime } : {}),
      };

      // Validate ECC Account IDs
      validateEccAccountIDs(finalEccAccountIDs);

      // Validate additional keys and entities
      validateObject(finalAdditionalKeys, 'additionalKeys');
      validateObject(finalEntities, 'entities');

      // Construct event info with current timestamp
      const eventInfo = {
        event_name: name,
        event_type: type,
        feature,
        capability,
        route,
        event_timestamp: timestamp ?? Date.now(),
        ...(partner && { partner }),
        ...(finalUserID && { user_id: finalUserID }),
        ecc_account_ids: preserveUndefined(finalEccAccountIDs),
        additional_keys: preserveUndefined(finalAdditionalKeys),
        entities: preserveUndefined(finalEntities)
      };

      window.aptrinsic('track', gainsightEventName, eventInfo);
    } catch (error) {
      this.captureError(
        new Error(`Failed to track GPX event: ${error.message}`),
        {
          error,
          context: {
            name,
            type,
            feature,
            capability,
            route,
            timestamp,
          }
        }
      );
    }
  }

  /**
   * Track Custom event
   * @deprecated This method is deprecated and will be removed in a future version.
   * Please use trackEvent() method instead for tracking custom events.
   * @param {string} category - app and category that calling the tracking
   * event, could be Data Map, Data CallQueue, like the component name we use
   * @param {object} eventInfo - any info that you want to be tracked
   * @param {number} eventInfo.'Launched date' - timestamp representing when
   * the event was triggered.
   */
  trackCustomEvent(category, eventInfo = {}) {
    try {
      if (!window.aptrinsic) {
        return; // Exit early if tracking not initialized
      }

      const userData = this.dataProvider?.getUserData() ?? {};
      const psapData = this.dataProvider?.getPSAPData() ?? {};

      // Set all fields in one place
      if (!eventInfo['Launched date']) eventInfo['Launched date'] = new Date();
      if (!eventInfo['Category']) eventInfo['Category'] = category;
      if (userData.profileId && !eventInfo['user_id']) eventInfo['user_id'] = userData.profileId;
      if (psapData.accountId && !eventInfo['ecc_account_ids']) {
        eventInfo['ecc_account_ids'] = { source: psapData.accountId };
      }

      // The first parameter has to be 'track' for custom event
      window.aptrinsic('track', category, eventInfo);
    } catch {
      // Silent error handling to match legacy behavior
    }
  }
}

/**
 * NOTE This returns a singleton of CapstoneGainSightPX
 */
export default new CapstoneGainSightPX();
