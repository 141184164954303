/**
 * Capabilities used throughout RSP.
 */
export const JURISDICTION_VIEW = 'jurisdiction_view_0';
export const INTERNATIONAL_SUPPORT = 'international_support_0';
export const ESRI_MAP = 'esri_map_0';

// Alerts
export const ALERTS_FIRE = 'alerts_fire_0';
export const ALERTS_LAW_ENFORCEMENT = 'alerts_law_enforcement_0';
export const ALERTS_MEDICAL = 'alerts_medical_0';
export const ALERTS_ACTIVE_ASSAILANT = 'alerts_active_assailant_0';
export const ALERTS_TRAIN_INCIDENT = 'alerts_train_incident_0';
export const ALERTS_TEST = 'alerts_test_mode_0';

// Spatial Layers
export const _911_INFORM = '_911_inform_2';
export const CRG = 'crg_2';
export const CRG_EXPANDED_MAP_CARD = 'crg_expanded_map_card_0';
export const AVIVE = 'avive';

// Agency Share and Chat
export const AGENCY_SHARE_CHAT = 'agency_share_chat_0';

// Alerts Routing
export const ALERTS_NOTIFICATION = 'alerts_notification_0';

// Premium Portal
export const RAPIDSOS_PREMIUM = 'rapidsos_premium_0';

// Agent511 (Text-to-911)
export const AGENT_511_LOCATION = 'agent_511_1';
export const AGENT_511_ADR = 'agent_511_2';

// GIS Services
export const GIS_SEARCH = 'gis_search_0';
export const GIS_SERVICES = 'gis_services_0';

export const AXON = 'axon_3';

// ANI/ALI Partners
export const ALI_GEOCOMM = 'ali_geocomm_1';
export const ALI_INDIGITAL = 'ali_indigital_1';

// Call Taker Mode
export const CALL_TAKER_MODE = 'call_taker_mode_0';

// Z-Axis
export const ESTIMATED_FLOOR_LEVELS_GEOCOMM =
  'estimated_floor_levels_geocomm_0';
export const VERTICAL_LOCATION_CONTENT_GEOCOMM =
  'vertical_location_content_geocomm_0';

// map attribution
export const MAP_ATTRIBUTION_GEOCOMM = 'map_attribution_geocomm_0';

// Call Status
export const CALL_STATUS = 'call_status_0';

// Apple ADR
export const APPLE_ADR = 'apple_2';

// Limited Alert Statuses
export const LIMITED_ALERT_STATUSES = 'limited_alert_statuses_0';

// Esri map sketch widget
export const MAP_MARKUP = 'map_markup_0';

// Esri map click to identify
export const CLICK_TO_IDENTIFY = 'click_to_identify_0';

export const TF911 = 'text_from_911_0';
export const VT911 = 'video_to_911_0';

// EagleView ObliqueImagery
export const EAGLEVIEW = 'eagleview_0';

export const MEDIA_UPLOAD = 'media_upload_0';
export const VIDEO_STREAMING = 'video_streaming_0';
export const DEVICE_MEDIA = 'apple_device_media_2';

export const SECURE911_OSN = 'secure911_0';
export const SECURE911_OSN_ADR = 'secure911_2';

// Communicator bundle Chat, Video, Location Request
export const COMMUNICATOR_CHAT = 'communicator_chat_0';
export const COMMUNICATOR_VIDEOCALL = 'communicator_videocall_0';
export const COMMUNICATOR_LOCATION_REQUEST = 'communicator_location_request_0';

export const UNITE_EVENT_PANE = 'unite_event_pane_0';

export const AIR_AMBULANCE = 'air_ambulance_0';

// Communicator Full - Transcription
export const COMMUNICATOR_TRANSCRIPTION = 'communicator_transcription_0';

// Communicator | HARMONY Transcription, Translation & Playback
export const UNITE_TRANSCRIPTION_AND_AUDIO_PLAYBACK =
  'unite_transcription_and_audio_playback_0';

// Communicator | HARMONY Summarization & Keywords
export const UNITE_SUMMARIZATION_AND_KEYWORDS =
  'unite_summarization_and_keywords_0';

// Communicator | HARMONY Radio Playback and Transcription
export const UNITE_RADIO_TRANSCRIPTION = 'unite_radio_transcription_0';

// Google RCS
export const GOOGLE_RCS = 'google_rcs_0';

// Communicator | HARMONY Text Translation
export const HARMONY_TEXT_TRANSLATION = 'harmony_text_translation_0';

// Communicator | HARMONY Video Transcription and Translation
export const HARMONY_VIDEO_TRANSCRIPTION_AND_TRANSLATION =
  'harmony_video_transcription_and_translation_0';

// Communicator - HARMONY Text-to-Voice Translation
export const HARMONY_TEXT_TO_VOICE_TRANSLATION =
  'harmony_text_to_voice_translation_0';
