const getUrlParameter = (name: string) => {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null || results[1] === undefined
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export default getUrlParameter;
