import md5 from 'md5';
import { getAPIHost } from '@rsos/base-utils/metaTags';
import { JURISDICTION_VIEW } from './constants/internalConstants';
import { safeParseDate, getEnvironmentSuffix } from './utils';

/**
 * Normalizes user information for Gainsight PX tracking.
 * This function handles both authenticated and unauthenticated users.
 *
 * For authenticated users, provide a full UserData object.
 * For unauthenticated users, you can provide a minimal object with just a profileId:
 * @example
 * // For unauthenticated users
 * normalizeUserInfo({ profileId: 'anonymous-123' }, trackingID)
 *
 * @param {import('./types').UserData} userData - User data from the data provider
 * @param {string} trackingID - The tracking ID for the environment
 * @returns {Object} Normalized user info for Gainsight PX
 * @throws {Error} If required user data is missing
 */
export function normalizeUserInfo(userData, trackingID) {
  // Validate input
  if (!userData) {
    throw new Error('Invalid user data: object is required');
  }
  if (!userData.profileId) {
    userData.profileId = 'unknown'
  }

  const id = `${userData.profileId}-${getEnvironmentSuffix(trackingID)}`;

  // Always include these basic fields
  const baseInfo = {
    appVersion: process.env.REACT_APP_FE_VERSION ?? '',
    host: getAPIHost(),
    id,
    trackingID
  };

  // If this is an unauthenticated user with just a profileId, return basic info
  if (Object.keys(userData).length === 1 && userData.profileId) {
    return baseInfo;
  }

  // For authenticated users, normalize all fields according to standards:
  // - String fields: null for missing values
  // - Date fields: null for missing values (handled by safeParseDate)
  return {
    ...baseInfo,
    appName: userData.application ?? null,
    email: userData.email ?? null,
    firstName: userData.firstName ?? null,
    hashedEmail: userData.email ? md5(userData.email.toLowerCase().trim()) : null,
    lastActive: safeParseDate(userData.lastActiveSession),
    lastName: userData.lastName ?? null,
    signUpDate: safeParseDate(userData.dateJoined),
    userRole: userData.role ?? null,
  };
}

/**
 * Normalizes account information for Gainsight PX tracking.
 * @param {import('./types').AccountData} accountData - Account data from the data provider
 * @returns {Object} Normalized account info for Gainsight PX
 * @throws {Error} If account data is not provided
 */
export function normalizeAccountInfo(accountData) {
  // Validate input
  if (!accountData) {
    throw new Error('Invalid account data: object is required');
  }

  // Normalize fields according to standards:
  // - String fields: null for missing values
  // - Numeric fields: 0 for missing values
  // - Boolean fields: !! for type coercion
  return {
    id: accountData.accountId ?? null,
    name: accountData.displayName ?? null,
    PrimaryPointOfContact: accountData.contactEmail ?? null,
    JurisdictionViewEnabled: !!accountData.capabilities?.[JURISDICTION_VIEW],
    AddressablePopulation: accountData.population ?? 0,
    AgencyState: accountData.state ?? null,
    DispatchType: accountData.dispatchType ?? null,
  };
}


