export const API_HOST_META_TAG_NAME = 'mco-api-host';
export const PRODUCTION_API_HOST = 'https://api.rapidsos.com';
export const SESSION_AGE = 'session-age';
export const MCO_ENV = 'mco-env';
export const REC_PRODUCT_NAME = 'rec-product-name';
export const GOOGLE_MAPS_KEY = 'google-maps-key';
export const APP_NAME = 'fe-app-name';
export const SENTRY_DSN = 'sentry-dsn';
export const WSS_QUERY_PARAM = 'wss-query-params';
export const GOOGLE_MAPID_DARK = 'google_mapid_dark';

/**
 * Reads the content value of the meta tag with the given 'name' value.
 * If no tag is found, it returns an empty string.
 * @param {string} name - The name for the meta tag
 */
export const readMetaTag = (name: string) => {
  const meta = document.querySelector(`meta[name='${name}']`);
  return (meta && meta.getAttribute('content')) || '';
};

/**
 * Returns the API host corresponding to the environment's stack.
 */
export const getAPIHost = () =>
  readMetaTag(API_HOST_META_TAG_NAME) || PRODUCTION_API_HOST;

/**
 * Returns the auto logged out session age in seconds
 */
export const getSessionAge = () => parseInt(readMetaTag(SESSION_AGE)) || 172800;

/**
 * Returns the environment corresponding to the current stack.
 */
export const getMCOEnvironment = () => readMetaTag(MCO_ENV) || '';

/**
 * Returns the REC product name corresponding to the current environment.
 */
export const getRECProductName = () =>
  readMetaTag(REC_PRODUCT_NAME) || 'REM-Sandbox';

/**
 * Returns the Google maps API key
 */
export const getGoogleMapsApiKey = () => readMetaTag(GOOGLE_MAPS_KEY);

/**
 * Return Google Maps' dark mode map ID.
 * @returns String
 */
export const getGoogleMapIdDark = () =>
  readMetaTag(GOOGLE_MAPID_DARK) || 'ac3492cc4c2da367';

/**
 * Returns the name of the current frontend application.
 */
export const getAppName = () => readMetaTag(APP_NAME) || '';

/**
 * Returns the Sentry DSN corresponding to the stack.
 */
export const getSentryDSN = () => readMetaTag(SENTRY_DSN) || '';

/**
 * Websocket query param string
 */
export const getWssQueryParam = () => readMetaTag(WSS_QUERY_PARAM) || '';
