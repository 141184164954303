export const GSPX_LAST_IDENTIFIED = 'GSPX_LAST_IDENTIFIED';

export const PRODUCTION_HOSTNAMES = [
    'rapidlite.rapidsos.com',
    'rapidsosportal.com',
];

export const GSPX_TRACKING_IDS = {
    prod: 'AP-PEXDVMYJXIFL-2',
    staging: 'AP-PEXDVMYJXIFL-2-2', //staging and sandbox
    qa: 'AP-PEXDVMYJXIFL-2-3',
    dev: 'AP-PEXDVMYJXIFL-2-4', // This is the key for dev
};

export const JURISDICTION_VIEW = 'jurisdiction_view_0';